var widthFlag = "";
var state = false;
var pos;
$(function () {
	// 画面サイズのチェック
	$(window).on("load resize", function () {
		widthCheck();
	});
});
// 画面サイズのチェック
function widthCheck() {
	// 画面幅取得
	var winWidth = $(window).width();
	// 画面幅599以下でフラグがspでない時
	if (winWidth <= 599 && widthFlag != "sp") {
		// フラグをSPに変更
		widthFlag = "sp";

		//リサイズ時のナビゲーション表示処理
		if ($("nav").css("display") == "block") {
			$("nav").css("display", "");
		}
		// 画面幅599よりおおきく、フラグがpcでない時
	} else if (winWidth > 599 && widthFlag != "pc") {
		// フラグをPCに変更
		widthFlag = "pc";
		if ($("body").hasClass("fixed")) {
			$("body").removeClass("fixed").css("top", "");
			state = false;
		}
		//リサイズ時のナビゲーション消失防止処理
		if ($("nav").css("display") == "none") {
			$("nav").css("display", "block");
		}
	}
}

//スマホハンバーガーメニュー
$(function () {
	$(".hamburger").on("click", function () {
		$(".menu-trigger").toggleClass("active");
		$("nav").slideToggle();
		//$(this).next().slideToggle();
		//背面スクロール禁止
		if (state == false) {
			pos = $(window).scrollTop();
			$("body").addClass("fixed").css({ top: -pos });
			state = true;
		} else {
			$("body").removeClass("fixed").css({ top: 0 });
			window.scrollTo(0, pos);
			state = false;
		}
	});
	$("nav a").on("click", function () {
		if ($(".menu-trigger").hasClass("active")) {
			$("nav").slideToggle();
			$(".menu-trigger").removeClass("active");
			$("body").removeClass("fixed");
		}
	});
});
