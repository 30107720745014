var widthFlag = "";
//連打防止フラグ
var click = true;
$(function () {
	// 画面サイズのチェック
	$(window).on("load resize", function () {
		widthCheck();
	});
});
// 画面サイズのチェック
function widthCheck() {
	// 画面幅取得
	var winWidth = $(window).width();
	// 画面幅767以下でフラグがspでない時
	if (winWidth <= 767 && widthFlag != "sp") {
		// フラグをSPに変更
		widthFlag = "sp";
		//console.log('spの処理');
		$(".accordion_box").hide();
		$(".accordion").on("click", function () {
			if (click) {
				click = false;
				$(this)
					.toggleClass("open")
					.siblings(".accordion_box")
					.toggleClass("open_box")
					.slideToggle(300, function () {
						click = true;
					});
				// $(".accordion_box")
				// 	.toggleClass("open_box")
				// 	.slideToggle(300, function () {
				// 		click = true;
				// 	});
			}
		});
		$(".space.exa .sideh_title").on("click", function () {
			if (click) {
				click = false;
				$(this)
					.toggleClass("open")
					.next()
					.slideToggle(300, function () {
						click = true;
					});
			}
		});
		//リサイズ時のナビゲーション表示処理
		// if ($('#nav').css('display') == 'block') {
		//   $('#nav').css('display', 'none');
		// }
		// 画面幅767よりおおきく、フラグがpcでない時
	} else if (winWidth > 767 && widthFlag != "pc") {
		// フラグをPCに変更
		widthFlag = "pc";
		//console.log('pcの処理');
		$(".accordion_box").show();
		$(".accordion").off();
		$(".accordion").removeClass("open");
		//リサイズ時のナビゲーション消失防止処理
		// if ($('#nav').css('display') == 'none') {
		//   $('#nav').css('display', 'block');
		// }
	}
}
