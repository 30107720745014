//TOP戻るボタンフェードイン・アウト
$(function () {
	let pagetop = $("#page-top");
	$(window).on("scroll", function () {
		if ($(window).scrollTop() > 100) {
			pagetop.addClass("fadein");
		} else {
			pagetop.removeClass("fadein");
		}
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();

		if (window.matchMedia("(max-width: 599px)").matches) {
			$("#news").each(function () {
				var imgPos = $(this).offset().top;
				if (scroll > imgPos - windowHeight + windowHeight / 5) {
					$("#page-top").removeClass("fadein");
				}
			});
		} else {
			$("footer").each(function () {
				var imgPos = $(this).offset().top;
				if (scroll > imgPos - windowHeight + windowHeight / 5) {
					$("#page-top").removeClass("fadein");
				}
			});
		}
	});
});
$(function () {
	$('a[href^="#"]:not(".notscroll")').on("click", function () {
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? "html" : href);
		var position = target.offset().top;
		var speed = 1000;
		$("html, body").animate(
			{
				scrollTop: position - 80,
			},
			speed,
			"swing"
		);
		return false;
	});
});
$(function () {
	// 別ページの場合は以下
	var urlHash = location.hash;
	if (urlHash) {
		$("body,html").stop().scrollTop(0);
		setTimeout(function () {
			// ヘッダー固定の場合はヘッダーの高さを数値で入れる、固定でない場合は0
			var headerHight = 80;
			var target = $(urlHash);
			var position = target.offset().top - headerHight;
			$("body,html").stop().animate({ scrollTop: position }, 400);
		}, 100);
	}
});
